<template>
  <div class="task-set-item-root-container">
    <span
      class="task-set-item-span"
      style="max-width: 200px; font-size: 15px; font-weight: bold"
      >{{ dataSource.name }}</span
    >
    <avatar-list
      :member-list="dataSource.user_arr"
      :max-count="7"
      style="margin-top: 20px"
    />
    <div class="task-set-item-row" style="margin-top: 20px">
      <Icon type="ios-list-box-outline" style="margin-right: 10px" />
      <span style="font-size: 12px; margin-right: 20px"
        >{{ dataSource.done_task_inventory_count }}/{{
          dataSource.total_task_inventory_count
        }}</span
      >
      <Icon type="ios-pin-outline" style="margin-right: 10px" />
      <span
        class="task-set-item-span"
        style="max-width: 100px; font-size: 12px; margin-right: 20px"
        >{{
          dataSource.address_arr.length > 0 ? dataSource.address_arr[0] : "-"
        }}</span
      >
      <Icon type="ios-attach" style="margin-right: 10px" />
      <span style="font-size: 12px">{{ dataSource.accessories_count }}</span>
    </div>
  </div>
</template>

<script>
import AvatarList from "../../../components/List/AvatarList";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "avatar-list": AvatarList,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.task-set-item-root-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 330px;
  margin: 10px;
}
.task-set-item-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.task-set-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.task-set-item-span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>