<template>
  <div class="avatar-list-root-container">
    <div @click="$emit('show-all')" class="avatar-count">
      <span style="font-size: 12px; color: white">{{ memberList.length }}</span>
    </div>
    <my-avatar
      style="margin-right: 0px"
      v-for="(n, index) in count"
      :key="index"
      :size="35"
      :name="memberList[n - 1].client_name"
    />
  </div>
</template>

<script>
import MyAvatar from "../MyAvatar";
export default {
  props: {
    memberList: Array,
    maxCount: {
      type: Number,
      default: 7,
    },
  },
  mounted() {},
  components: {
    "my-avatar": MyAvatar,
  },
  computed: {
    count() {
      return this.memberList.length <= this.maxCount
        ? this.memberList.length
        : this.maxCount;
    },
  },
};
</script>

<style scoped>
.avatar-list-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.avatar-count {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f66767;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>