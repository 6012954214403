<template>
  <div class="archive-root-container">
    <div class="archive-main-container">
      <task-set-item
        v-for="item in taskList"
        :key="item.task_id"
        :dataSource="item"
        @click.native="goDetail(item)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import TaskSetItem from "./Item/TaskSetItem";
import * as icon from "../../common/icon";
export default {
  components: {
    "task-set-item": TaskSetItem,
  },
  data() {
    return {
      icons: icon,
      projectId: 0,
      taskList: [],
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.taskGetArchiveListAction({
      employer_id: localStorage.getItem("employer_id"),
      project_id: this.projectId,
    })
      .then((res) => {
        for (let item of res) {
          item.address_arr = JSON.parse(item.address_arr);
        }
        this.taskList = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      taskGetArchiveListAction: "taskGetArchiveList",
    }),
    goDetail(item) {
      this.$router.push({
        name: "task-info",
        params: {
          tid: item.task_id,
          pid: this.projectId,
        },
      });
    },
  },
};
</script>

<style scoped>
.archive-root-container {
  display: flex;
  flex-direction: column;
  padding-right: 150px;
}
.archive-main-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>